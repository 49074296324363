import type { MaybeRef } from 'vue'
import type { Dayjs } from 'dayjs'
import type { YearType } from '@manager'
import { calculateEndDateByYearType, calculateNextStartDate } from './utils'

export interface IUseDatesGeneratorDates {
  /* Lease start date */
  startDate?: MaybeRef<string | Date | null | undefined>
  /* Lease end date */
  endDate?: MaybeRef<string | Date | null | undefined>
  /* Financial year date (MM-DD) */
  financialYearDate?: MaybeRef<string | undefined>
}

export interface IUseDatesGeneratorContext {
  /* Item start date */
  startDate: Dayjs
  /* Item end date */
  endDate: Dayjs
}

export interface IUseDatesGeneratorOptions {
  onAdd: (index: number, context: IUseDatesGeneratorContext) => void
  onRemove: (index: number) => void
  maxIterations?: number
}

export function useDatesGenerator<
  C extends Record<string, any> = Record<string, any>,
>(globalDates: IUseDatesGeneratorDates, options: IUseDatesGeneratorOptions) {
  const { onAdd, onRemove, maxIterations = Infinity } = options

  const dayjs = useDayjs()

  let totalIndex = 0

  const generate = (
    yearType: YearType,
    localDates: IUseDatesGeneratorDates = {},
    context: () => C = () => ({}) as C,
  ) => {
    const startDate = toDayjs(
      resolveDate(localDates.startDate, globalDates.startDate),
    )
    const endDate = toDayjs(
      resolveDate(localDates.endDate, globalDates.endDate),
    )
    const financialYearDate = resolveDate(
      localDates.financialYearDate,
      globalDates.financialYearDate,
    )

    if (!startDate || !endDate) {
      console.warn(
        '[useDatesGenerator]: Couldn\'t generate dates because either "startDate" or "endDate" are not defined.',
      )
      return
    }

    /* Start date of the current iteration */
    let currentStartDate = startDate
    let index = 0

    // Remove items that are not in the generated years
    const removeItems = () => {
      if (currentStartDate.isSameOrAfter(endDate)) {
        for (let i = totalIndex - 1; i >= index; i--) {
          onRemove(i)
        }
      }
    }

    while (currentStartDate.isBefore(endDate) && index < maxIterations) {
      /* End date of the current iteration */
      const currentEndDate = calculateEndDateByYearType(
        yearType,
        currentStartDate,
        endDate,
        financialYearDate,
      )

      if (!currentEndDate) return

      onAdd(index, { startDate: currentStartDate, endDate: currentEndDate })

      // Update the start date for the next year
      currentStartDate = calculateNextStartDate(
        yearType,
        currentStartDate,
        currentEndDate,
      )
      index++
    }

    removeItems()
    totalIndex = index
  }

  return { generate }

  function resolveDate<T extends string | Date | null | undefined>(
    localDate: MaybeRef<T>,
    globalDate: MaybeRef<T>,
  ) {
    return unref(localDate) ?? unref(globalDate)
  }

  function toDayjs<T extends string | Date | null | undefined>(date: T) {
    return date ? dayjs.utc(date) : undefined
  }
}
