import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'

export function calculateFinancialYear(
  currentStartDate: Dayjs,
  leaseEndDate: Dayjs,
  financialYearDate?: string,
) {
  if (!financialYearDate) return

  let endDate = dayjs
    .utc(financialYearDate, 'MM-DD')
    .set('year', currentStartDate.year())

  if (currentStartDate.isSameOrAfter(endDate)) {
    endDate = endDate.add(1, 'year')
  }

  return endDate.isBefore(leaseEndDate) ? endDate : leaseEndDate
}
